<template>
  <div class="card" style="display: flex; flex-direction: column">
    <span style="font-size: 3dvh; font-weight: bold; margin-bottom: 1dvh"
      >Интегральды сөйлеу синтезі</span
    >
    <span>
      Типтік статистикалық параметрлік сөйлеу синтезі немесе терең оқыту
      негізіндегі сөйлеу синтезі - бұл бірнеше модульдерден тұратын күрделі
      конвейер, оның ішінде мәтінді фонемаға түрлендіру желісі, дыбыстық
      сегментация желісі, фонеманың ұзақтығын болжау желісі, негізгі жиілікті
      болжау желісі және вокодер бар. Бұл модульдерді құру үлкен кәсіби тәжірибе
      мен көп уақытты қажет етеді. Сонымен қатар, әр модульдегі мүмкін қателер
      модельді оқытуды қиындатуы мүмкін. Сөйлеуді синтездеу жүйесін жеңілдету
      және адамның араласуы мен лингвистикалық тәжірибе қажеттілігін жою
      интегралды (end-to-end) тәсілдерді ынталандырады. Интегралды модель
      жеткілікті деректер жиынын үйреткеннен кейін берілген мәтіннен сигналды
      тікелей синтездей алады. Терең оқытуға негізделген ең жетілдірілген
      интеграцияланған TTS модельдері адамның дерлік сөзін синтездеу мүмкіндігін
      көрсетті. Ол негізінен үш компоненттен тұрады: мәтінді талдау, акустикалық
      модель және вокодер. Бірінші компонент берілген мәтінді стандартты
      құрылымға түрлендіреді. Алдыңғы кезеңнің шығуында екінші компонент жасаған
      аралық акустикалық сипаттамалар ұзақ мерзімді сөйлеу құрылымын модельдеу
      үшін қолданылады. Үшінші компонент акустикалық сипаттамалардан толқын
      пішінінің үлгілерін жасау үшін пайдаланылады. Бұл компоненттердің барлығы
      әдетте жеке дайындалады және баламалы түрде оларды бірге реттеуге болады.
      WaveNet, Tacotron және SampleRNN сияқты терең оқытуға негізделген сөйлеу
      синтезі әдістерінің бірқатар кемшіліктері бар. Бұл үлгілерді үйрету көп
      уақытты алады. Синтезделген сөйлеу әдетте эмоционалдылық пен ырғақтан
      айырылады. Сонымен қатар, мұндай модельдерді оқытуға сөйлеу деректері
      жиынтығының көлемі айтарлықтай әсер етеді. Сөйлеу синтезі жүйесінің дамуы
      лингвистика, акустика, цифрлық сигналды өңдеу және машиналық оқыту сияқты
      бірнеше пәндерді қамтиды. Зерттеу нысаны болып берілген мәтінді сөйлеу
      форматына түрлендіру процесі табылатындықтан, ол адам мен машинаның өзара
      әрекеттесуіне арналған кең ауқымды қосымшаларда орын алады.</span
    >
  </div>
</template>
<script>
export default {};
</script>
<style></style>
